<template>
  <div class="container">
    <div
      class="q-pt-lg"
      v-if="Object.keys(getShowPublicMission).length"
      :class="$q.platform.is.mobile ? 'q-pa-md' : 'page-body'"
    >
      <div class="row no-wrap justify-between q-pa-sm bg-white default-rounded">
        <q-btn
          flat
          icon="reply"
          color="black-11"
          @click="getPublicReturn()"
          :label="$t('global.back')"
        />
        <div>
          <q-img
            style="width: 90px; margin-right: 20px;"
            :src="getShowPublicMission.partner.logo_foto_link"
          />
        </div>
      </div>

      <div class="q-mt-lg">
        <show-mission-header-component :mission="getShowPublicMission" />
      </div>
      <div class="row q-mt-lg wrap-reverse">
        <show-mission-body-component
          @wantEnroll="alertModal = true"
          :mission="getShowPublicMission"
        />
      </div>
    </div>
    <footer-component />
  </div>
  <public-alert-component
    v-if="Object.keys(getShowPublicMission).length"
    v-model="alertModal"
    :mission_title="getShowPublicMission.title"
    :slug="getShowPublicMission.slug"
    :company_slug="getShowPublicMission.partner.slug"
    :requirements="getShowPublicMission.requirements"
  />
</template>

<script>
import PublicAlertComponent from "./components/item-components/PublicAlertComponent.vue";
import ShowMissionHeaderComponent from "./components/ShowMissionHeaderComponent.vue";
import ShowMissionBodyComponent from "./components/ShowMissionBodyComponent.vue";
import FooterComponent from "../../components/FooterComponent.vue";
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { useRoute, useRouter } from "vue-router";
import { LocalStorage, useQuasar } from "quasar";
import { onBeforeMount, ref } from "vue";

const { useActions, useGetters } = createNamespacedHelpers("missions");
export default {
  name: "PublicMissionPage",

  components: {
    ShowMissionHeaderComponent,
    ShowMissionBodyComponent,
    PublicAlertComponent,
    FooterComponent,
  },

  setup() {
    const { getShowPublicMission } = useGetters(["getShowPublicMission"]),
      { fetchShowPublic } = useActions(["fetchShowPublic"]),
      alertModal = ref(false),
      $q = useQuasar(),
      router = useRouter(),
      route = useRoute();

    const getPublicReturn = () => {
      const slug = LocalStorage.getItem("returnPublic"),
        returnconf = {
          name: `public.missions${slug != "undefined" ? ".company" : ""}`,
        };

      if (slug != "undefined") returnconf.params = { slugCompany: slug };
      router.push(returnconf);
    };

    onBeforeMount(async () => {
      $q.loading.show();
      await fetchShowPublic({ slug: route.params.slug })
        .catch(() => router.push({ name: "404" }))
        .finally(() => $q.loading.hide());
    });

    return {
      alertModal,
      getShowPublicMission,
      getPublicReturn,
    };
  },
};
</script>

<style scoped lang="scss">
@import "src/styles/app.scss";
.container {
  background-color: $publicBackgroundColor;
}
.page-body {
  width: 85%;
  margin: auto;
}
@media only screen and (max-width: 1600px) {
  .page-body {
    width: 94%;
  }
}
</style>
