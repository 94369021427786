<template>
  <q-dialog transition-show="slide-up" transition-hide="slide-down" persistent>
    <q-card class="form">
      <div class="q-pa-xs">
        <q-card-section class="row items-center q-pb-lg">
          <div
            style="font-size: 14pt"
            class="text-uppercase text-grey-8 q-mt-sm"
            v-html="$t('missions.prerequisites_for_mission')"
          />
          <q-space />
          <q-btn icon="close" color="red" flat round dense v-close-popup />
        </q-card-section>

        <q-separator class="q-mr-md q-ml-md" />

        <q-card-section class="q-pt-none q-mt-md bg-grey-2 q-ma-sm q-pa-md">
          <div class="q-pa-sm text-justify">
            <span
              ><q-icon name="info" size="20px" class="q-mr-xs" />
              {{
                $t(
                  requirements.length
                    ? "missions.public_modal"
                    : "missions.public_modal_no_requirements"
                )
              }}</span
            >
            <div
              v-for="requirement in requirements"
              :key="requirement.id"
              class="q-mb-sm q-mt-md"
            >
              <q-icon name="arrow_right" size="20px" class="q-mr-sm" />
              <span
                v-html="
                  requirement.value && requirement.helper
                    ? $t('missions.requirement', {
                        value: getRequirement(
                          requirement.helper,
                          requirement.value
                        ),
                      })
                    : requirement.description
                "
              />
            </div>
          </div>
        </q-card-section>
        <q-card-actions align="center">
          <q-btn
            color="black"
            class="q-ml-sm"
            :loading="in_request"
            @click="redirect(slug)"
            :label="$t('missions.go_to_mission')"
          />
        </q-card-actions>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { createNamespacedHelpers } from "vuex-composition-helpers";
import { generateUtmUrl } from "@/shared/helpers/utmHelper";
import { setLoginRoute } from "@/modules/partner/storage";
import checkAuth from "@/router/middleware/checkAuth";
import { useRouter } from "vue-router";
import { LocalStorage } from "quasar";
import { ref } from "vue";

const { useActions } = createNamespacedHelpers("auth");
const partnerNamespace = createNamespacedHelpers("partner");

export default {
  name: "PublicAlertComponent",

  props: {
    slug: {
      type: String,
      required: true,
    },
    mission_title: {
      type: String,
      required: true,
    },
    requirements: {
      type: Array,
      required: true,
    },
    company_slug: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const userLogged = ref(false),
      in_request = ref(false),
      router = useRouter(),
      getRequirement = (helper, value) => helper.replace("{value}", value);

    const { slugPartner } = partnerNamespace.useGetters({
        slugPartner: "getPartnerSlug",
      }),
      { fetchPartnerBySlugAction } = partnerNamespace.useActions({
        fetchPartnerBySlugAction: "fetchPartnerBySlugAction",
      }),
      { signOutAction } = useActions({
        signOutAction: "signOutAction",
      });

    const setUtmParams = () => {
      const utmParams = {
        utm_source: "flipnet",
        utm_medium: "missoes",
        utm_campaign: props.mission_title.replace(/ /g, "_"),
      };

      generateUtmUrl(utmParams);
    };

    const redirect = async (slug) => {
      userLogged.value = await checkAuth();
      in_request.value = true;

      if (!userLogged.value) {
        setLoginRoute(`/missions/${slug}`);
        redirectRoute("login", props.company_slug);
        setUtmParams();
      } else {
        if (slugPartner.value != props.company_slug) {
          setUtmParams();
          await signOutAction();
          await fetchPartnerBySlugAction({ slug: props.company_slug });
          setLoginRoute(`/missions/${slug}`);
          redirectRoute("login", props.company_slug);
        } else {
          LocalStorage.set("wanna_enroll", true);
          redirectRoute("view-mission", slug);
        }

        in_request.value = false;
      }
    };

    const redirectRoute = (name, slug) =>
      router.push({
        name,
        params: { slug },
      });

    return {
      in_request,
      redirect,
      getRequirement,
    };
  },
};
</script>

<style lang="scss" scoped>
.form {
  width: 600px;
  max-width: 80vw;
}
.mobile {
  .form {
    min-width: 98vw;
  }
}
</style>
